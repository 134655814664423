<template>
  <div id="dialogSettingRawMaterial">
    <v-dialog v-model="dialog" max-width="500" persistent>
      <v-card>
        <v-card-title>
          <span class="headline">{{ dialogTitle }}</span
          ><v-divider class="mx-4" inset vertical></v-divider>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" md="4">
                <div>
                  <img
                    class="preview my-3"
                    :src="previewOriginal"
                    style="width: 180px; height: 180px"
                    alt=""
                  />
                </div>
              </v-col>
              <v-col cols="12" v-if="isEdit">
                <v-text-field
                  v-model="item.material_id"
                  label="รหัส"
                  dense
                  required
                  readonly
                  disabled
                  ref="material_id"
                  @keydown.enter="NextFocus('material_name')"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="item.material_name"
                  label="ชื่อ"
                  dense
                  required
                  ref="material_name"
                  @keydown.enter="NextFocus('minimum')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12">
                <v-autocomplete
                  v-model="item.material_type_id"
                  :items="materialTypeList"
                  item-value="material_type_id"
                  item-text="material_type_name"
                  :return-object="false"
                  dense
                  label="ประเภทวัตถุดิบ"
                  ref="unit"
                  @keydown.enter="NextFocus('description')"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="item.minimum"
                  type="number"
                  label="ขั้นต่ำ"
                  dense
                  required
                  ref="minimum"
                  @keydown.enter="NextFocus('qty')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="item.qty"
                  type="number"
                  label="คงเหลือ"
                  dense
                  required
                  ref="qty"
                  @keydown.enter="NextFocus('unit')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-autocomplete
                  v-model="item.unit_id"
                  :items="unitList"
                  item-value="unit_id"
                  item-text="unit_name"
                  :return-object="false"
                  dense
                  label="หน่วย"
                  ref="unit"
                  @keydown.enter="NextFocus('description')"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6">
                <v-autocomplete
                  v-model="item.cal_stock"
                  :items="cal_stock_list"
                  item-value="val"
                  item-text="show"
                  :return-object="false"
                  dense
                  label="ตัดสต๊อก"
                  ref="status"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" v-if="isEdit">
                <v-autocomplete
                  v-model="item.status"
                  :items="enumStatus"
                  item-value="id"
                  item-text="name"
                  :return-object="false"
                  dense
                  label="สถานะ"
                  ref="status"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="12">
                <v-text-field
                  v-model="item.description"
                  type="string"
                  value="0"
                  label="หมายเหตุ"
                  dense
                  required
                  ref="description"
                  @keydown.enter="NextFocus('unit_id')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="5">
                <v-file-input
                  v-model="filename"
                  show-size
                  label="Select Image"
                  accept="image/*"
                  @change="selectImage"
                ></v-file-input>
                <div v-if="previewImage">
                  <div>
                    <img
                      class="preview my-3"
                      :src="previewImage"
                      style="width: 180px; height: 180px"
                      alt=""
                    />
                  </div>
                </div>
              </v-col>
              <v-col cols="12" md="3">
                <v-btn color="success" dark small @click="upload">
                  Upload
                  <v-icon right dark>mdi-cloud-upload</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="primary--text" @click="cancel"> ยกเลิก </v-btn>
          <v-btn class="primary" @click="submit"> บันทึก </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import baseEnum from "@js/enum";
import { mapActions, mapState } from "vuex";

export default {
  name: "DialogSettingRawMaterial",
  props: ["modal", "item", "itemIndex"],
  data() {
    return {
      currentImage: null,
      previewImage: null,
      filename: null,
      imagePath: "",
      previewOriginal: "",
      enumStatus: baseEnum.status.data,
      dialog: false,
      item_edit: {},
      indexEdit: -1,
      unitList: [],
      materialTypeList: [],
      cal_stock_list: [
        {
          val: 0,
          show: "ไม่ใช่"
        },
        {
          val: 1,
          show: "ใช่"
        }
      ]
    };
  },
  mounted() {
    this.getUnitList();
    this.getMaterialType();
  },
  computed: {
    ...mapState("auth", ["user"]),
    dialogTitle() {
      return this.indexEdit == -1 ? "เพิ่ม" : "แก้ไข";
    },
    isEdit() {
      return this.indexEdit == -1 ? false : true;
    },
  },
  methods: {
    ...mapActions("settings", [
      "materialCreate",
      "materialEdit",
      "actUnitGets",
      "actUnitTinyGets",
      "getMaterialTypeAll",
      "uploadMaterialImage"
    ]),
    closeModal() {
      this.$emit("close", false);
      this.previewOriginal = ""
    },
    cancel() {
      this.closeModal();
    },
    selectImage(image) {
      if (image) {
      this.currentImage = image;
      this.previewImage = URL.createObjectURL(this.currentImage);
      this.message = "";
    }
    },
    getUnitList() {
      this.actUnitTinyGets().then(({ status, data }) => {
        if (status == 200 && data.status == "success") {
          const listData = data.result.map((row)=>{
            const {UnitId, UnitName, unit_id, unit_name} = row
            const result = {
              ...row,
              unit_id: UnitId ? UnitId : unit_id,
              unit_name: UnitName ? UnitName : unit_name
            }
            return result
          })
          console.log(listData)
          this.unitList = listData
        } else {
          this.Error("เกิดข้อผิดพลาด : " + data.result);
        }
      });
    },
    getMaterialType() {
      this.getMaterialTypeAll().then(({ status, data }) => {
        if (status == 200 && data.status == "success") {
          this.materialTypeList = data.result
        } else {
          this.Error("เกิดข้อผิดพลาด : " + data.result);
        }
      });
    },
    upload() {
      if (!this.currentImage) {
        this.message = "Please select an Image!";
        return;
      }

      var bodyFormData = new FormData();
      bodyFormData.append("file", this.currentImage);
      if (this.previewOriginal != "") {
        bodyFormData.append("delete_url", this.previewOriginal);
      }

      this.uploadMaterialImage(bodyFormData).then(({ status, data }) => {
        if (status == 200 && data.status == "success") {
          this.imagePath = data.result;
          this.previewOriginal = this.imagePath;

          this.currentImage = null;
          this.previewImage = null;
          this.filename = null;
        } else {
          this.Error(data.result);
        }
      });
    },
    submit() {
      this.currentImage = null;
      this.previewImage = null;
      this.filename = null;
      this.previewOriginal = null;

      const payload_body = {
        material_id: this.item.material_id,
        material_name: this.item.material_name || "",
        material_type_id: this.item.material_type_id,
        minimum: this.item.minimum || 0,
        qty: this.item.qty || 0,
        unit_id: this.item.unit_id || 0,
        status: this.item.status === 0 ? this.item_edit.status : 1,
        description: this.item.description || "",
        cal_stock: this.item.cal_stock,
        created_by: this.user.UserId,
        modified_by: this.user.UserId,
        photo: this.imagePath
      }
      console.log("payload_body", payload_body)


      if (this.indexEdit == -1) {
        //เพิ่ม
        this.materialCreate(payload_body).then(({ status, data }) => {
          if (status == 200 && data.status == "success") {
            this.closeModal();
          } else {
            this.Error(data.result);
          }
        });
      } else {
        //แก้ไข
        console.log(this.item);
        this.materialEdit(payload_body).then(({ status, data }) => {
          if (status == 200 && data.status == "success") {
            this.closeModal();
          } else {
            this.Error(data.result);
          }
        });
      }
      this.imagePath = null
    },
  },
  watch: {
    modal() {
      this.dialog = this.modal;
    },
    item() {
      this.item_edit = this.item;
      this.item_edit.minimum = this.item.minimum || 0;
      this.item_edit.qty = this.item.qty || 0;
      this.previewOriginal = this.item_edit.photo
      this.imagePath = this.item_edit.photo
    },
    itemIndex() {
      this.indexEdit = this.itemIndex;
    },
  },
};
</script>

<style></style>
